//React Components
import { useEffect, useState } from "react";
//Routing
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Theme with Material UI
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
//Theme state management
import { themeLight, themeDark } from "./Themes/Theme";

import MainAppBar from "./Components/Navigation/AppBar";
import DashBoard from "./Pages/Dashboards/DashBoard";
//Auth
import SignIn from "./Pages/Auth/SignIn";
import PrivateRoute from "./Components/PrivateRoute";
import ResetPassword from "./Pages/Auth/ResetPassword";

// Admin
import UserManagement from "./Pages/Admin/UserManagement";
import EditUser from "./Pages/Admin/Pages/EditUser";
import AddNewUser from "./Pages/Admin/Pages/AddNewUser";
import ForgottenPassword from "./Pages/Auth/ForgottenPassword";

import CompaniesManagement from "./Pages/Admin/CompaniesManagement";
import Company from "./Pages/Admin/Pages/Company";

//RMA

import Notification from "./Pages/AppGlobal/Notification";
import { useLocalStorage } from "./Hooks/useStorage";
import Profile from "./Pages/AppGlobal/Profile";

//CDA

// CDA Call Center
import CallCenter from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CallCenter";
import Campaigns from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Campaigns";

//CDA QA

import PageNotFound from "./Pages/AppGlobal/PageNotFound";

//Surveys
import Survey from "./Pages/Companies/CDA/CallCenter/Surveys/Survey";

import SurveyUsers from "./Pages/Companies/CDA/CallCenter/Surveys/SurveyUsers";
import TakeSurvey from "./Pages/Companies/CDA/CallCenter/Surveys/Components/SurveyUserComponents/TakeSurvey";
import ViewSurvey from "./Pages/Companies/CDA/CallCenter/Surveys/Components/SurveyUserComponents/ViewSurvey";
import SurveyResults from "./Pages/Companies/CDA/CallCenter/Surveys/Components/SurveyUserComponents/SurveyResults";
import CompletedSurvey from "./Pages/Companies/CDA/CallCenter/Surveys/Components/SurveyUserComponents/CompletedSurvey";
import ResultsQuestionsAnswers from "./Pages/Companies/CDA/CallCenter/Surveys/Components/SurveyUserComponents/ResultsQuestionsAnswers";
import CreateSurvey from "./Pages/Companies/CDA/CallCenter/Surveys/Components/Survey/CreateSurvey";
import SurveyQuestionsAnswers from "./Pages/Companies/CDA/CallCenter/Surveys/Components/Survey/SurveyQuestionsAnswers";
import SurveyItem from "./Pages/Companies/CDA/CallCenter/Surveys/Components/Survey/SurveyItem";

// Questionnaire

import UserQuestionnaires from "./Pages/Companies/CDA/CallCenter/Questionnaires/UserQuestionnaires";
import TakeQuestionnaire from "./Pages/Companies/CDA/CallCenter/Questionnaires/TakeQuestionnaire";
import QuestionnaireEndPage from "./Pages/Companies/CDA/CallCenter/Questionnaires/QuestionnaireEndPage";
import QuestionnaireResults from "./Pages/Companies/CDA/CallCenter/Questionnaires/QuestionnaireResults";
import ViewQuestionnaireDetails from "./Pages/Companies/CDA/CallCenter/Questionnaires/ViewQuestionnaireDetails";

import CustomerRelationshipManagement from "./Pages/CRM/CustomerRelationshipManagement";
import ClientsList from "./Pages/CRM/Clients/ClientsList";
import ClientView from "./Pages/CRM/Clients/ClientView";
import CRMCampaign from "./Pages/CRM/Campaigns/CRMCampaign";
import Santam from "./Pages/Companies/Santam/Index";
import SalaryDeductionsList from "./Pages/Companies/Santam/pages/SalaryDeductionsList";
import CRMCampaigns from "./Pages/CRM/Campaigns/CRMCampaigns";
import ChatApp from "./ChatApp";
import Dispositions from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Dispositions";
import TeamsManagement from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/TeamsManagement";
import Clients from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Clients";
import TeamsEdit from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/TeamsEdit";

import ViewClient from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/ViewClient";
import LeadSourcesManage from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/LeadSourcesManage";

import QaConsole from "./Pages/Companies/CDA/CallCenter/QAManagement/QaConsole";
import QaQueues from "./Pages/Companies/CDA/CallCenter/QAManagement/QaQueues";
import QATeams from "./Pages/Companies/CDA/CallCenter/QAManagement/QATeams";
import QaTeamEdit from "./Pages/Companies/CDA/CallCenter/QAManagement/QaTeamEdit";
import QaQueueManage from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/QaQueueManage";
import QaAllQuestions from "./Pages/Companies/CDA/CallCenter/QAManagement/QaAllQuestions";

import CampaignTeamsManagement from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignTeamsManagement";
import CampaignDispositionManage from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignDispositionManage";
import AgentHome from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/AgentHome";
import QaAgentHome from "./Pages/Companies/CDA/CallCenter/QaAgent/QaAgentHome";
import QaQueuesSetup from "./Pages/Companies/CDA/CallCenter/QAManagement/QaQueuesSetup";
import CampaignLeads from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/CampaignLeads";
import Lead from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/Lead";
import CampaignList from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/CampaignList";
import AgentSales from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/AgentSales";
import Sales from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Sales";
import CampaignLeadsManage from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignLeads";
import LeadsAdmin from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/LeadsAdmin";
import LeadFileUploader from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/LeadFileUploader";

// Campaign Management
import ManageCampaign from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/ManageCampaign";
import CampaignManageTabs from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignManageTabs";
import CampaignLeadSources from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignLeadSources";
import CampaignLeadFileUploader from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignLeadFileUploader";
import AdVoiceSMS from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/AdVoiceSMS";
import CampaignPlans from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignPlans";
import CampaignPlan from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Components/Plans/CampaignPlan";
import ViewCampaignPlan from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Components/Plans/ViewCampaignPlan";
import EditCampaignPlan from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Components/Plans/EditCampaignPlan";
import AddCampaignPlan from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/Components/Plans/AddCampaignPlan";
import CampaignSales from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CampaignSales";

//Reports
import ReportsMain from "./Pages/Companies/CDA/CallCenter/Reports/ReportsMain";

import AgentReports from "./Pages/Companies/CDA/CallCenter/Reports/AgentReports";
//RMA

import FileUploader from "./Pages/Companies/RMA/Pages/FileUploader";
import CreateCallCenterCampaign from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/CreateCallCenterCampaign";
// import ReportsMain from "./Pages/Companies/CDA/CallCenter/Reports/ReportsMainOld";
import Evaluation from "./Pages/Companies/CDA/CallCenter/QAManagement/Evaluation";
import QaQueueTeams from "./Pages/Companies/CDA/CallCenter/QAManagement/QaQueueTeams";
import QaQueueEdit from "./Pages/Companies/CDA/CallCenter/QAManagement/QaQueueEdit";
import QueueEvaluations from "./Pages/Companies/CDA/CallCenter/QAManagement/QueueEvaluations";
import MoveEvaluations from "./Pages/Companies/CDA/CallCenter/QAManagement/MoveEvaluations";
import MoveEvaluationsAdmin from "./Pages/Companies/CDA/CallCenter/QAManagement/MoveEvaluationsAdmin";
import QueueQuestionAndAnswersManage from "./Pages/Companies/CDA/CallCenter/QAManagement/QueueQuestionAndAnswersManage";
import StartEvaluation from "./Pages/Companies/CDA/CallCenter/QaAgent/StartEvaluation";
import EvaluationResults from "./Pages/Companies/CDA/CallCenter/QaAgent/EvaluationResults";
import EditEvaluation from "./Pages/Companies/CDA/CallCenter/QaAgent/Evaluations/EditEvaluation";

// Sales Orders
import SaleOrderManagement from "./Pages/Companies/CDA/SaleOrders/SaleOrderManagement";
import SaleOrderModal from "./Pages/Companies/CDA/SaleOrders/SaleOrderModal";

// Payspace Onboarding
import OnboardingManager from "./Pages/Onboarding/MANAGER/Manager";
import OEMEmployee from "./Pages/Onboarding/MANAGER/Employees";
import OEMRequisitions from "./Pages/Onboarding/MANAGER/Requisition";
import OEMAddRequisition from "./Pages/Onboarding/MANAGER/AddRequisition";
import OEMPositions from "./Pages/Onboarding/MANAGER/Positions";
import OEMDepartments from "./Pages/Onboarding/MANAGER/Departments";

import OnboardingCEO from "./Pages/Onboarding/CEO/Ceo";
import OECRequisitions from "./Pages/Onboarding/CEO/Requisition";
import OECEmployee from "./Pages/Onboarding/CEO/Employees";
import OECPositions from "./Pages/Onboarding/CEO/Positions";
import OECDepartments from "./Pages/Onboarding/CEO/Departments";
import OECAddRequisition from "./Pages/Onboarding/CEO/AddRequisition";

import OnboardingHR from "./Pages/Onboarding/HR/HR";
import OEHRRequisition from "./Pages/Onboarding/HR/Requisition";
import OEHRAddRequisition from "./Pages/Onboarding/HR/AddRequisition";
import OEHREmployee from "./Pages/Onboarding/HR/Employees";
import OEHRDepartments from "./Pages/Onboarding/HR/Departments";
import OEHRPositions from "./Pages/Onboarding/HR/Positions";

import OERegister from "./Pages/Onboarding/Employee/Register";
import GetOTP from "./Pages/Onboarding/getOTP";
import VerifyOTP from "./Pages/Onboarding/VerifyOTP";

import Employees from "./Pages/Admin/Pages/Employees";
import EditDispositionList from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/EditDispositionList";

import CreateNewEvaluation from "./Pages/Companies/CDA/CallCenter/QAManagement/CreateNewEvaluation";
import AllEvaluations from "./Pages/Companies/CDA/CallCenter/QAManagement/AllEvaluations";
import TargetsMain from "./Pages/Companies/CDA/CallCenter/CallCenterTargets/TargetsMain";
import AddNewTargets from "./Pages/Companies/CDA/CallCenter/CallCenterTargets/AddNewTargets";
import UserList from "./Pages/Companies/CDA/CallCenter/CallCenterTargets/UserList";
import CallCenterAbsenteeism from "./Pages/Companies/CDA/CallCenter/CallCenterAbsenteeism/CallCenterAbsenteeism";
import TeamLeaderLanding from "./Pages/Companies/CDA/CallCenter/TeamLeader/TeamLeaderLanding";
import CampaignTeamLeaderList from "./Pages/Companies/CDA/CallCenter/TeamLeader/CampaignTeamLeaderList";
import Attendance from "./Pages/Companies/CDA/CallCenter/CallCenterAbsenteeism/Attendance";
import CaptureAbsenteeism from "./Pages/Companies/CDA/CallCenter/CallCenterAbsenteeism/CaptureAbsenteeism";
import GoogleVision from "./GoogleVision";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CampaignSalesStats from "./Pages/Companies/CDA/CallCenter/TeamLeader/CampaignSalesStats";

import LeadHome from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/LeadHome";
import LeadSales from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/LeadSales";
import SaleQa from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/SalesQa";
import ViewEvaluation from "./Pages/Companies/CDA/CallCenter/QaAgent/ViewEvaluation";

import AddExistingQuestionToQueue from "./Pages/Companies/CDA/CallCenter/QAManagement/AddExistingQuestionToQueue";
import CampaignLinking from "./Pages/Companies/CDA/CallCenter/QAManagement/CampaignLinking";
import CoachingHome from "./Pages/Companies/CDA/CallCenter/Coaching/CoachingHome";
import NewScoreCardForm from "./Pages/Companies/CDA/CallCenter/Coaching/NewScoreCardForm";
import ListOfScoreCards from "./Pages/Companies/CDA/CallCenter/Coaching/ListOfScoreCards";
import ViewScoreCard from "./Pages/Companies/CDA/CallCenter/Coaching/ViewScoreCard";

import SMSList from "./Pages/Onboarding/Components/SMSList";
import Questionnaire from "./Pages/Companies/CDA/CallCenter/Questionnaires/Questionnaire";
import CreateQuestionnaire from "./Pages/Companies/CDA/CallCenter/Questionnaires/Components/CreateQuestionaire";
import ViewQuestionnaire from "./Pages/Companies/CDA/CallCenter/Questionnaires/Components/ViewQuestionnaire";
import AgentEvaluations from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/AgentEvaluations";

import AgentReportsDashBoard from "./Pages/Companies/CDA/CallCenter/CallCenterAgents/AgentReportsDashBoard";

import GlobalCampaignTargets from "./Pages/Companies/CDA/CallCenter/CampaignsManagement/GlobalCampaignTargets";
import DevelopmentReports from "./Pages/Companies/CDA/CallCenter/Reports/DevelopmentReports";
import QueueReports from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/QueueReports";
import QaMain from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/QaMain";
import AgentQaReport from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/AgentQaReport";
import CampaignQaReports from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/CampaignQaReports";
import DashboardsMain from "./Pages/Companies/CDA/Dashboards/DashboardsMain";
import ExcoMain from "./Pages/Companies/CDA/Dashboards/Exco/ExcoMain";
import Main from "./Pages/Companies/CDA/CallCenter/VBC/Main";
import QaAgentReport from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/QaAgentReport";
import SalesVsQaReports from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/SalesVsQaReports";
import IncompleteEvaluationsReport from "./Pages/Companies/CDA/CallCenter/Reports/QaReports/IncompleteEvaluationsReport";
import AgentResults from "./Pages/Companies/CDA/CallCenter/QaAgent/AgentResults";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_KEY}`);

const App = () => {
  console.log(
    "%cHey, if you are looking at this... Stop",
    "color: #ff0000; font-size: 20px;",
  );

  const [themeStorage, setStorage] = useLocalStorage("theme", true);

  const [theme, setTheme] = useState(themeStorage);

  useEffect(() => {
    setStorage(theme);
  }, [theme, setStorage]);

  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={!theme ? themeDark : themeLight}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Router>
          <MainAppBar currentTheme={theme} setTheme={setTheme}>
            <Routes>
              <Route path="/" element={<PrivateRoute />}>
                {/* APP GLOBAL  */}

                <Route path="/" element={<DashBoard />} />

                <Route path="/CDA/VBCreports" element={<Main />} />

                <Route path="/DashboardsMain" element={<DashboardsMain />}>
                  <Route index path="ExcoMain" element={<ExcoMain />} />
                </Route>

                <Route path="/notifications" element={<Notification />} />
                <Route path="/ChatApp" element={<ChatApp />} />
                <Route path="/GoogleVision" element={<GoogleVision />} />

                {/* ADMIN */}
                <Route path="/UserManagement" element={<UserManagement />} />
                <Route path="/EditUser/:id" element={<EditUser />} />
                <Route path="/AddNewUser" element={<AddNewUser />} />
                <Route
                  path="/CompaniesManagement"
                  element={<CompaniesManagement />}
                />
                <Route path="/Employees" element={<Employees />} />
                <Route path="/Company/:id" element={<Company />} />
                {/* ADMIN */}
                {/* Call Center Admin Panel  */}
                <Route path="/CDA/CallCenter" element={<CallCenter />}>
                  <Route index path="clients" element={<Clients />} />
                  <Route path="Campaigns" element={<Campaigns />} />
                  <Route path="Dispositions" element={<Dispositions />} />
                  <Route path="teams" element={<TeamsManagement />} />
                  <Route path="Sales" element={<Sales />} />
                  <Route path="leadSources" element={<LeadSourcesManage />} />
                  <Route path="LeadsAdmin" element={<LeadsAdmin />} />
                  <Route path="Plans" element={<CampaignPlans />} />
                  <Route
                    path="GlobalCampaignTargets"
                    element={<GlobalCampaignTargets />}
                  />
                </Route>

                <Route
                  path="/CDA/CallCenter/Coaching"
                  element={<CoachingHome />}
                >
                  <Route
                    index
                    path="ListOfScoreCards"
                    element={<ListOfScoreCards />}
                  />
                  <Route
                    path="NewScoreCardForm/:id"
                    element={<NewScoreCardForm />}
                  />
                  <Route path="ViewScoreCard/:id" element={<ViewScoreCard />} />
                </Route>

                <Route
                  path="/CDA/SalesOrders"
                  element={<SaleOrderManagement />}
                />
                <Route
                  path="/CDA/SalesOrders/:id"
                  element={<SaleOrderModal />}
                />
                {/* Call Center Admin Panel  */}
                {/* Call Center Admin Campaign Plans  */}
                <Route
                  path="/CDA/CallCenter/Plans/create"
                  element={<AddCampaignPlan />}
                />
                <Route
                  path="/CDA/CallCenter/Plans/:client_id"
                  element={<CampaignPlan />}
                />
                <Route
                  path="/CDA/CallCenter/Plans/:client_id/view/:id"
                  element={<ViewCampaignPlan />}
                />
                <Route
                  path="/CDA/CallCenter/Plans/:client_id/edit/:id"
                  element={<EditCampaignPlan />}
                />

                <Route
                  path="/CDA/CallCenter/CreateCampaign"
                  element={<CreateCallCenterCampaign />}
                />

                <Route
                  path="/CDA/CallCenter/TeamLeader"
                  element={<TeamLeaderLanding />}
                >
                  <Route
                    index
                    path="CampaignList"
                    element={<CampaignTeamLeaderList />}
                  />
                  <Route
                    path="CampaignSalesStats/:id"
                    element={<CampaignSalesStats />}
                  />
                </Route>

                <Route
                  path="/CDA/CallCenter/TargetsMain"
                  element={<TargetsMain />}
                >
                  <Route index path="UserList" element={<UserList />} />
                  <Route path="AddNewTargets" element={<AddNewTargets />} />
                </Route>
                {/* Absentism Routes */}
                <Route
                  path="/CDA/CallCenter"
                  element={<CallCenterAbsenteeism />}
                >
                  <Route path="Attendance" element={<Attendance />} />
                  <Route
                    path="CaptureAttendance"
                    element={<CaptureAbsenteeism />}
                  />
                </Route>
                {/* Call Center Admin Campaign Management  */}
                <Route
                  path="/CDA/ManageCampaign/:id/edit"
                  element={<CampaignManageTabs />}
                >
                  <Route index path="Campaign" element={<ManageCampaign />} />
                  <Route
                    path="dispositions"
                    element={<CampaignDispositionManage />}
                  />
                  <Route path="teams" element={<CampaignTeamsManagement />} />
                  <Route
                    path="LeadFileUploader"
                    element={<CampaignLeadFileUploader />}
                  />
                  <Route path="LeadsManage" element={<CampaignLeadsManage />} />

                  <Route path="CampaignSales" element={<CampaignSales />} />
                  <Route path="QaQueueManage" element={<QaQueueManage />} />
                  <Route
                    path="CampaignLeadSources"
                    element={<CampaignLeadSources />}
                  />
                  <Route path="AdVoiceSMS" element={<AdVoiceSMS />} />
                </Route>
                {/* Call Center Admin Campaign Management  */}

                {/* Reports */}
                {/* <Route path="/Reports" element={<ReportsMain />} /> */}
                <Route path="/reports" element={<ReportsMain />}>
                  <Route path="agents" element={<AgentReports />} />
                  <Route path="QaMain" element={<QaMain />}>
                    <Route
                      index
                      path="QueueReports"
                      element={<QueueReports />}
                    />
                    <Route path="AgentQaReport" element={<AgentQaReport />} />
                    <Route path="QaAgentReport" element={<QaAgentReport />} />
                    <Route
                      path="CampaignQaReports"
                      element={<CampaignQaReports />}
                    />
                    <Route
                      path="SalesVsQaReports"
                      element={<SalesVsQaReports />}
                    />
                    <Route
                      path="IncompleteEvaluationsReport"
                      element={<IncompleteEvaluationsReport />}
                    />
                  </Route>

                  <Route path="development" element={<DevelopmentReports />} />
                </Route>

                {/* Reports */}

                {/*LEAD FILE UPLOAD */}

                <Route
                  path="/CDA/LeadFileUploader/:id"
                  element={<LeadFileUploader />}
                />
                {/*lEAD FILE UPLOAD */}

                {/* ADMIN CAMPAIGN LEADS */}
                <Route
                  path="/CDA/CampaignLeadsManage/:id"
                  element={<CampaignLeadsManage />}
                />
                {/* ADMIN CAMPAIGN LEADS */}

                <Route
                  path="/CDA/QaQueueManage/:id"
                  element={<QaQueueManage />}
                />
                <Route path="/CDA/LeadsAdmin" element={<LeadsAdmin />} />
                <Route path="/CDA/TeamsEdit/:id" element={<TeamsEdit />} />
                <Route path="/CDA/ViewClient/:id" element={<ViewClient />} />
                <Route
                  path="/CDA/callCenter/dispositions/:id"
                  element={<EditDispositionList />}
                />

                <Route path="/CDA/QaConsole" element={<QaConsole />}>
                  <Route path="QaQueues" element={<QaQueues />} />
                  <Route path="QATeams" element={<QATeams />} />
                  <Route path="QaAllQuestions" element={<QaAllQuestions />} />
                  <Route path="AllEvaluations" element={<AllEvaluations />} />
                  <Route path="CampaignLinking" element={<CampaignLinking />} />
                </Route>

                <Route path="/CDA/QaAgentHome" element={<QaAgentHome />} />
                <Route path="/CDA/AgentResults" element={<AgentResults />} />
                <Route
                  path="/CDA/QaAgentHome/:id"
                  element={<ViewEvaluation />}
                />
                <Route
                  path="/CDA/StartEvaluation/:id/:uni_id"
                  element={<StartEvaluation />}
                />
                <Route
                  path="/CDA/EvaluationResults/:id/:uni_id"
                  element={<EvaluationResults />}
                />

                <Route
                  path="/CDA/Evaluation/CreateNewEvaluation/:id"
                  element={<CreateNewEvaluation />}
                />

                <Route
                  path="/CDA/Evaluation/EditEvaluation/:id"
                  element={<EditEvaluation />}
                />
                <Route path="/CDA/QATeams/:id" element={<QaTeamEdit />} />
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                <Route
                  path="/CDA/QaQueuesSetup/:id/Evaluations/:eval"
                  element={<Evaluation />}
                />
                <Route
                  path="/CDA/QaQueuesSetup/:id"
                  element={<QaQueuesSetup />}
                >
                  <Route
                    path="/CDA/QaQueuesSetup/:id/teams"
                    element={<QaQueueTeams />}
                  />
                  <Route
                    path="/CDA/QaQueuesSetup/:id/qaQueue"
                    element={<QaQueueEdit />}
                  />
                  <Route
                    path="/CDA/QaQueuesSetup/:id/Evaluations"
                    element={<QueueEvaluations />}
                  />
                  <Route
                    path="/CDA/QaQueuesSetup/:id/MoveEvaluations"
                    element={<MoveEvaluations />}
                  />
                  <Route
                    path="/CDA/QaQueuesSetup/:id/MoveEvaluationsAdmin"
                    element={<MoveEvaluationsAdmin />}
                  />
                  <Route
                    path="/CDA/QaQueuesSetup/:id/QuestionAndAnswers"
                    element={<QueueQuestionAndAnswersManage />}
                  />
                  <Route
                    path="/CDA/QaQueuesSetup/:id/AddExistingQuestionToQueue"
                    element={<AddExistingQuestionToQueue />}
                  />
                </Route>
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* /////////////////////////////////// */}
                {/* CALL CENTER USERS */}
                <Route path="/CDA/AgentHome" element={<AgentHome />}>
                  <Route index path="CampaignList" element={<CampaignList />} />
                  <Route path="AgentSales" element={<AgentSales />} />
                  <Route
                    path="AgentEvaluations"
                    element={<AgentEvaluations />}
                  />
                  <Route
                    path="AgentReportsDashBoard"
                    element={<AgentReportsDashBoard />}
                  />
                </Route>

                <Route
                  path="/CDA/CampaignLeads/:id"
                  element={<CampaignLeads />}
                />

                <Route path="/CDA/CampaignLeads/Lead" element={<LeadHome />}>
                  <Route
                    index
                    path="/CDA/CampaignLeads/Lead/:id/lead"
                    element={<Lead />}
                  />
                  <Route
                    index
                    path="/CDA/CampaignLeads/Lead/:id/sales"
                    element={<LeadSales />}
                  />
                  <Route
                    index
                    path="/CDA/CampaignLeads/Lead/:id/qa"
                    element={<SaleQa />}
                  />
                </Route>

                {/* CALL CENTER USERS */}

                {/* Survey */}

                <Route path="/CDA/surveyAdmin" element={<Survey />} />
                <Route path="/CDA/survey" element={<SurveyUsers />} />
                <Route path="/CDA/survey/results" element={<SurveyResults />} />
                <Route
                  path="/CDA/SurveyResults/QuestionsAnswers/:id"
                  element={<ResultsQuestionsAnswers />}
                />
                <Route path="/CDA/survey/create" element={<CreateSurvey />} />
                <Route
                  path="/CDA/Survey/QuestionsAnswers"
                  element={<SurveyQuestionsAnswers />}
                />
                <Route
                  path="/CDA/Survey/surveyItem/:id"
                  element={<SurveyItem />}
                />
                <Route path="/CDA/Survey/:id" element={<TakeSurvey />} />
                {/* <Route
                  path="/CDA/Survey/:id/:companyId"
                  element={<TakeSurvey />}
                /> */}
                <Route
                  path="/CDA/Survey/:id/:contactId"
                  element={<TakeSurvey />}
                />
                <Route
                  path="/CDA/Survey/:id/:companyId/CompletedSurvey"
                  element={<CompletedSurvey />}
                />
                <Route
                  path="/CDA/ViewSurvey/:id/:survey_user_id"
                  element={<ViewSurvey />}
                />
                {/* Surveys  */}

                {/* Questionnaire  */}
                <>
                  <Route
                    path="/CDA/questionnaireAdmin"
                    element={<Questionnaire />}
                  />
                  <Route
                    path="/CDA/questionnaire/create"
                    element={<CreateQuestionnaire />}
                  />
                  <Route
                    path="/CDA/questionnaire/:id"
                    element={<ViewQuestionnaire />}
                  />
                  <Route
                    path="/CDA/UserQuestionnaires"
                    element={<UserQuestionnaires />}
                  />
                  <Route
                    path="/CDA/TakeQuestionnaire/:id"
                    element={<TakeQuestionnaire />}
                  />
                  <Route
                    path="/CDA/QuestionnaireEndPage/:id"
                    element={<QuestionnaireEndPage />}
                  />
                  <Route
                    path="/CDA/QuestionnaireResults/:id"
                    element={<QuestionnaireResults />}
                  />
                  <Route
                    path="/CDA/ViewQuestionnaireDetails/:id"
                    element={<ViewQuestionnaireDetails />}
                  />
                </>
                {/* Questionnaire  */}

                {/* CRM */}
                <Route
                  path="/CRM"
                  element={<CustomerRelationshipManagement />}
                />
                <Route
                  path="/CRM/clients/clientsList"
                  element={<ClientsList />}
                />
                <Route
                  path="/CRM/clients/clientView/:id"
                  element={<ClientView />}
                />
                <Route path="/crm/crmCampaign/:id" element={<CRMCampaign />} />
                <Route path="/crm/campaigns" element={<CRMCampaigns />} />
                {/* CRM */}

                {/* SANTAM */}
                <Route path="/santam" element={<Santam />}></Route>
                <Route
                  index
                  path="/santam/SalaryDeductionsList"
                  element={<SalaryDeductionsList />}
                />
                {/* SANTAM */}

                {/* //RMA Routes */}
                <Route path="/RMA/FileUploader" element={<FileUploader />} />
                <Route path="/profile" element={<Profile />} />

                {/* Onbording new employeee new routes */}

                <Route path="onboarding">
                  <Route path="manager" element={<OnboardingManager />}>
                    <Route
                      index
                      path="requisition"
                      element={<OEMRequisitions />}
                    />
                    <Route
                      path="AddRequisition"
                      element={<OEMAddRequisition />}
                    />
                    <Route path="employees" element={<OEMEmployee />} />
                    <Route path="positions" element={<OEMPositions />} />
                    <Route path="departments" element={<OEMDepartments />} />
                    {/* <Route path="SMSList" element={<SMSList />} /> */}
                  </Route>

                  <Route path="hr" element={<OnboardingHR />}>
                    <Route
                      index
                      path="requisition"
                      element={<OEHRRequisition />}
                    />
                    <Route
                      path="AddRequisition"
                      element={<OEHRAddRequisition />}
                    />
                    <Route path="employees" element={<OEHREmployee />} />
                    <Route path="positions" element={<OEHRPositions />} />
                    <Route path="departments" element={<OEHRDepartments />} />

                    <Route path="SMSList" element={<SMSList />} />
                  </Route>

                  <Route path="ceo" element={<OnboardingCEO />}>
                    <Route
                      index
                      path="requisition"
                      element={<OECRequisitions />}
                    />
                    <Route
                      path="AddRequisition"
                      element={<OECAddRequisition />}
                    />
                    <Route path="employees" element={<OECEmployee />} />
                    <Route path="positions" element={<OECPositions />} />
                    <Route path="departments" element={<OECDepartments />} />

                    <Route path="SMSList" element={<SMSList />} />
                  </Route>
                </Route>
              </Route>

              {/* APP PUBLIC */}
              <Route path="/sign-in" element={<SignIn />} />
              <Route
                path="/ForgottenPassword"
                element={<ForgottenPassword />}
              />
              <Route path="/ResetPassword/:id" element={<ResetPassword />} />
              <Route path="*" element={<PageNotFound />} />

              <Route path="/otp" element={<GetOTP />} />
              <Route path="/otp/verify" element={<VerifyOTP />} />

              <Route path="/register" element={<OERegister />} />
            </Routes>
          </MainAppBar>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
