import { AppBar, Container, Stack, Tab, Tabs } from "@mui/material";
import React from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";

const AgentHome = ({ user, modules }) => {
  const navigate = useNavigate();

  // if (
  //   modules &&
  //   modules.includes("call-center-admin") &&
  //   !user.userType === "admin"
  // ) {
  //   navigate("/");
  // }

  const { pathname } = useLocation();

  return (
    <Container maxWidth="xl" disableGutters>
      <PageHeader
        title="Agent Home"
        subheader="Manage Sales and Leads"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
          {
            title: "Agent Home",
            href: "/CDA/AgentHome",
          },
        ]}
      />

      <AppBar position="static" color="inherit">
        <Tabs value={pathname.split("/")[3]}>
          <Tab
            label="Campaigns"
            value="CampaignList"
            onClick={() => navigate("/CDA/AgentHome/CampaignList")}
          />
          <Tab
            label="My Sales"
            value="AgentSales"
            onClick={() => navigate("AgentSales")}
          />
          <Tab
            label="My Evaluations"
            value="AgentEvaluations"
            onClick={() => navigate("AgentEvaluations")}
          />
        </Tabs>
      </AppBar>
      <Stack sx={{ mt: 4 }}>
        <Outlet />
      </Stack>
    </Container>
  );
};

export default withAuth(AgentHome);
